import { ResponsiveCalendar } from "@nivo/calendar";
import { calendarData } from "../../bookingsCalendar";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const Calendar = () => {
  let fromDays = subDays(new Date(), 180);
  let toDays = addDays(new Date(), 180);

  const theme = useTheme();
  const mode = useSelector((state) => state.auth.mode);

  let backgroundColor = "";
  let textColor = "";
  let tooltipColor;
  let emptyColor;
  let dagBorderColor;

  if (mode === "dark") {
    backgroundColor = theme.palette.background.paper;
    textColor = "#ffffff";
    tooltipColor = "#777777";
    emptyColor = theme.palette.background.paper;
    dagBorderColor = theme.palette.background.default;
  } else {
    backgroundColor = theme.palette.background.paper;
    textColor = "#777777";
    tooltipColor = "#777777";
    emptyColor = theme.palette.background.paper;
    dagBorderColor = theme.palette.background.default;
  }

  return (
    <ResponsiveCalendar
      data={calendarData}
      height={290}
      theme={{
        textColor: textColor,
        background: backgroundColor,

        fontSize: 11,

        legends: {
          text: {
            fill: textColor,
          },
        },
        tooltip: {
          container: {
            color: tooltipColor,
          },
        },
      }}
      from={fromDays}
      to={toDays}
      emptyColor={emptyColor}
      colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
      margin={{ top: 20, right: 10, bottom: 50, left: 25 }}
      yearSpacing={50}
      monthBorderColor={dagBorderColor}
      monthLegendOffset={8}
      dayBorderWidth={1}
      dayBorderColor={dagBorderColor}
      legends={[
        {
          anchor: "bottom-right",
          direction: "row",
          translateY: -35,
          itemCount: 4,
          itemWidth: 20,
          itemHeight: 20,
          itemsSpacing: 10,
          itemDirection: "top-to-bottom",
        },
      ]}
    />
  );
};

export default Calendar;

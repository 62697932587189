import React, { useState } from "react";
import { Avatar, Grid, TextField, Button, Divider, Box } from "@mui/material";
import UploadComponent from "./UploadComponent";
import { Formik } from "formik";
import * as yup from "yup";

const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const editChaletSchema = yup.object().shape({
  chaletName: yup.string().required("Chalet name is required"),
  //   chaletNumber: yup.number().required("Chalet number is required"),
  chaletNumber: yup
    .number()
    .typeError("Please specify a chaletnumber")
    .min(1, "Min value 1.")
    .max(36, "Max value 36.")
    .required("Chalet number is required"),
  address: yup.string().required("Address is required"),
  zipcode: yup.string().required("Zipcode is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  // mainImage: yup.string().required("Image is required"),
  mainImage: yup.mixed().required("Image is required"),
});

const ChaletFormik = ({
  //chalet,
  mainImage,
  mainImagePreview,
  chaletName,
  chaletNumber,
  address,
  zipcode,
  city,
  country,
  //handleInputChange,
  handleImageChange,
  handleClose,
  saveChalet,
}) => {
  const [newMainImagePreview, setNewMainImagePreview] = useState(mainImage);

  const initialValues = {
    chaletName: chaletName,
    chaletNumber: chaletNumber,
    address: address,
    zipcode: zipcode,
    city: city,
    country: country,
    mainImage: mainImage,
  };

  const [data, setData] = useState(initialValues);

  const handleMainImagePreview = (acceptedFiles) => {
    setNewMainImagePreview(URL.createObjectURL(acceptedFiles));
  };

  return (
    <Formik
      //enableReinitialize
      onSubmit={saveChalet}
      initialValues={initialValues}
      validationSchema={editChaletSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                autoFocus
                label="Chalet name"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.chaletName}
                name="chaletName"
                error={
                  Boolean(touched.chaletName) && Boolean(errors.chaletName)
                }
                helperText={touched.chaletName && errors.chaletName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                // autoFocus
                label="Chalet number"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.chaletNumber}
                name="chaletNumber"
                error={
                  Boolean(touched.chaletNumber) && Boolean(errors.chaletNumber)
                }
                helperText={touched.chaletNumber && errors.chaletNumber}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                // autoFocus
                label="Address"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.address}
                name="address"
                error={Boolean(touched.address) && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                fullWidth
                //  autoFocus
                label="Zipcode"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.zipcode}
                name="zipcode"
                error={Boolean(touched.zipcode) && Boolean(errors.zipcode)}
                helperText={touched.zipcode && errors.zipcode}
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <TextField
                fullWidth
                //autoFocus
                label="City"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.city}
                name="city"
                error={Boolean(touched.city) && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                //   autoFocus
                label="Country"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.country}
                name="country"
                error={Boolean(touched.country) && Boolean(errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>

            {/* DROPZONE BOX */}

            <Grid item xs={12} md={9}>
              <UploadComponent
                setFieldValue={setFieldValue}
                setData={setData}
                data={data}
                handleImageChange={handleImageChange}
                // handleImageChange={`${SERVER_URL}${handleImageChange}`}
                handleMainImagePreview={handleMainImagePreview}
                // handleMainImagePreview={`${SERVER_URL}${handleMainImagePreview}`}
              />
              {errors.mainImage && touched.mainImage ? (
                <Box sx={{ fontSize: "0.75rem" }} color="#d32f2f">
                  {errors.mainImage}
                </Box>
              ) : null}
              {/* </Box> */}
            </Grid>

            <Grid item xs={12} md={3}>
              <Avatar
                variant="square"
                sx={{ m: "auto" }}
                alt="Chalet"
                // src={
                //   `${SERVER_URL}${mainImagePreview?.filePath}` ||
                //   `${SERVER_URL}${newMainImagePreview}`
                // }
                // src={
                //   `${SERVER_URL}${mainImagePreview?.filePath}` ||
                //   `${newMainImagePreview}`
                // }
                // src={
                //   SERVER_URL + mainImagePreview?.filePath || newMainImagePreview
                // }
                src={
                  mainImagePreview?.filePath
                    ? `${SERVER_URL}${mainImagePreview?.filePath}`
                    : `${newMainImagePreview}`
                }
                style={{
                  width: 120,
                  height: 120,
                }}
              />

              {/* ))} */}
              {/* </Box> */}
            </Grid>
            {/* </Grid> */}

            <Divider />
            {/* BUTTONS */}
            <Grid container spacing={0} justifyContent="flex-end">
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  m: "2rem 1rem",
                }}
              >
                SAVE CHALET
              </Button>

              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  m: "2rem 0",
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ChaletFormik;

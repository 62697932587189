import { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar";
import SideBar from "components/Sidebar";
import { ShowOnLogin } from "components/protect/HiddenLinks";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <>
      {/* {isLoading && <Spinner />} */}

      <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
        <ShowOnLogin>
          <SideBar
            // when no data object then user is an empty object to prevent from a error
            // user={user || {}}
            isNonMobile={isNonMobile}
            drawerWidth="200px"
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </ShowOnLogin>
        {/* with flexGrow the box will fill to the right  */}
        <Box flexGrow={1}>
          <Navbar
            // when no data object then user is an empty object to prevent from a error
            // user={user.firstName || {}}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          {/* In the Outlet the elements from the routes will be rendered */}
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;

  return (
    <Dialog open={confirmDialog.isOpen}>
      <DialogTitle>
        <HighlightOffRoundedIcon color="error" style={{ fontSize: "60px" }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">{confirmDialog.title}</Typography>
        <Typography variant="body">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={confirmDialog.onConfirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Popup from "components/Popup";
import { useState } from "react";
import EditBooking from "pages/editBooking/EditBooking";

const EditBookingAction = ({ params }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleEditBooking = () => {
    setOpenPopup(true);
  };

  return (
    <>
      <Box>
        <Tooltip title="Edit Booking">
          <IconButton size="small" color="info" onClick={handleEditBooking}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popup
        title="Cleaning Hours Registration"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditBooking
          id={params.row._id}
          bookingNumber={params.row.bookingNumber}
          chaletName={params.row?.chalet?.chaletName}
          startDate={params.row.startDate}
          endDate={params.row.endDate}
          nights={params.row.nights}
          lastName={params.row.lastName}
          adults={params.row.adults}
          children={params.row.children}
          baby={params.row.baby}
          animals={params.row.animals}
          type={params.row.type}
          cleaningHours={params.row.cleaningHours}
          winterHours={params.row.winterHours}
          summerHours={params.row.summerHours}
          managementHours={params.row.managementHours}
          bookingStatus={params.row.bookingStatus}
          partner={params.row.partner}
          closePopup={closePopup}
          // handleSave={handleSave}
        />
      </Popup>
    </>
  );
};

export default EditBookingAction;

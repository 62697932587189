import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FlexBetween from "components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import Popup from "components/Popup";
import { useDispatch } from "react-redux";
import { getChalets } from "redux/store/chalet/chaletSlice";
import EditChalet from "components/chalet/EditChalet";

const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const ChaletInfo = ({
  id,
  chaletName,
  chaletNumber,
  address,
  zipcode,
  city,
  country,
  mainImage,
  firstName,
  lastName,
  email,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleSave = () => {
    setOpenPopup(false);
    dispatch(getChalets());
  };

  return (
    <>
      <Box m="1rem " backgroundColor="background.paper" sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            md={2}
          >
            <Avatar
              variant="square"
              sx={{ mt: 1, ml: 1, mb: 1 }}
              alt="Chalet"
              src={`${SERVER_URL}${mainImage?.filePath}`}
              style={{
                width: 114,
                height: 114,
              }}
            />
          </Grid>
          <Grid item xs={12} md={10} textAlign="right" m="2">
            <Paper sx={{ p: 2, m: 1, height: 120 }}>
              <FlexBetween>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Typography variant="h5">
                    {chaletName} ({chaletNumber})
                  </Typography>
                  <Typography variant="body2">{address}</Typography>
                  <Typography variant="body2">
                    {zipcode} {city}
                  </Typography>
                  <Typography variant="body2">{country}</Typography>
                  {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <IconButton
                    color="secondary"
                    aria-label="edit chalet"
                    onClick={() => {
                      setOpenPopup(true);
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="caption">Property owner</Typography>
                  <Typography variant="body2">
                    {firstName} {lastName}
                  </Typography>

                  <Box
                    gap="0.5rem"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <EmailOutlinedIcon fontSize="0.5rem" />
                    <Typography variant="body2">{email}</Typography>
                  </Box>
                </Box>
              </FlexBetween>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Popup
        title="Edit Chalet Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditChalet
          id={id}
          chaletName={chaletName}
          chaletNumber={chaletNumber}
          address={address}
          zipcode={zipcode}
          city={city}
          country={country}
          mainImage={mainImage}
          closePopup={closePopup}
          handleSave={handleSave}
        />
      </Popup>
    </>
  );
};

export default ChaletInfo;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";

const ChaletContracts = () => {
  useRedirectLoggedOutUser("/");

  //   const [expanded, setExpanded] = React.useState(false);

  //   const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  return (
    <>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      > */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Villa4You</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">Contract PDF Villa4you</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      > */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6">Housekeeper</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">Contract with housekeeper</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ChaletContracts;

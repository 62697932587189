import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  Avatar,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
//import uploadFile from "../../firebase/uploadFile";
import { registerUser } from "services/authService";
import { SET_LOGIN, SET_FIRSTNAME, SET_ROLE } from "redux/store/auth/authSlice";
import Loader from "components/loader/Loader";

const registerSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Please enter a valid email"),
  password: yup.string().required("Password is required"),
  address: yup.string().required("Address is required"),
  zipcode: yup.string().required("Zip code is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  phoneNumber: yup.string().required("Phone number is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  address: "",
  zipcode: "",
  city: "",
  country: "",
  phoneNumber: "",
  profileImage: "",
  role: "",
};

const Register = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);

  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // const UploadComponent = (props) => {
  //   const { setFieldValue, setFormData } = props;

  //   const { getRootProps, getInputProps } = useDropzone({
  //     accept: {
  //       "image/*": [],
  //     },
  //     onDrop: (acceptedFiles) => {
  //       setFieldValue("profileImage", acceptedFiles);

  //       setFormData({
  //         ...formData,
  //         // profileImage: acceptedFiles[0].name,
  //         profileImage: acceptedFiles,
  //       });

  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       );
  //     },
  //   });

  // return (
  //   <Box
  //     {...getRootProps({ className: "dropzone" })}
  //     border={`0.5px dashed `}
  //     p="2rem"
  //     sx={{ "&:hover": { cursor: "pointer" } }}
  //   >
  //     <input {...getInputProps()} />
  //     <Box>Drag & drop your profile picture here</Box>
  //   </Box>
  // );
  // };

  const handleFormSubmit = async (values, onSubmitProps) => {
    setIsLoading(true);
    // setTimeout(async function () {
    try {
      // console.log("formData:", formData);
      //formData.append("profileImage", productImage);

      const data = await registerUser(formData);
      //console.log(data);
      await dispatch(SET_LOGIN(true));
      await dispatch(SET_FIRSTNAME(data.firstName));
      //await dispatch(SET_USER(data));
      await dispatch(SET_ROLE(data.role));

      // if (data && values.picture) {
      //   const url = await uploadFile(
      //     values.picture[0],
      //     `/profile/${data._id}/ProfilePicture`
      //   );
      //   console.log("URL image:", url);
      // }

      setIsLoading(false);

      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);
    }
    // }, 6000);
    // onSubmitProps.resetForm();
  };

  return (
    <Box>
      {isLoading && <Loader />}
      <Paper
        sx={{
          width: isNonMobile ? "50%" : "93%",
          padding: "2rem",
          margin: "1rem  auto",
          backgroundColor: "background.paper",
        }}
        elevation={3}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb="1rem"
        >
          <Avatar
            sx={{
              bgcolor: "secondary.main",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Box>

        <Typography textAlign="center" variant="h4" sx={{ mb: "1.5rem" }}>
          Sign Up
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={registerSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <>
                  <TextField
                    label="First Name"
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.firstName}
                    name="firstName"
                    error={
                      Boolean(touched.firstName) && Boolean(errors.firstName)
                    }
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.lastName}
                    name="lastName"
                    error={
                      Boolean(touched.lastName) && Boolean(errors.lastName)
                    }
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Address"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.address}
                    name="address"
                    error={Boolean(touched.address) && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Zipcode"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.zipcode}
                    name="zipcode"
                    error={Boolean(touched.zipcode) && Boolean(errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="City"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.city}
                    name="city"
                    error={Boolean(touched.city) && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    label="Country"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.country}
                    name="country"
                    error={Boolean(touched.country) && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Phone"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.phoneNumber}
                    name="phoneNumber"
                    error={
                      Boolean(touched.phoneNumber) &&
                      Boolean(errors.phoneNumber)
                    }
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    sx={{ gridColumn: "span 4" }}
                  />

                  {/* DROPZONE BOX */}

                  {/* <Box sx={{ gridColumn: "span 3" }}>
                    <UploadComponent
                      setFieldValue={setFieldValue}
                      setFormData={setFormData}
                    />
                    {errors.profileImage && touched.profileImage ? (
                      <Box sx={{ fontSize: "0.75rem" }} color="#d32f2f">
                        {errors.profileImage}
                      </Box>
                    ) : null}
                  </Box>
                  <Box display="flex" gridColumn="span 1">
                    {values.profileImage &&
                      values.profileImage.map((file, i) => (
                        <Avatar
                          sx={{ m: "auto" }}
                          margin-bottom="auto"
                          margin-top="auto"
                          key={i}
                          // variant={"rounded"}
                          alt="User Profile"
                          src={file.preview}
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                          }}
                          style={{
                            width: 64,
                            height: 64,
                          }}
                        />
                      ))}
                  </Box> */}

                  <TextField
                    label="Email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    // value={values.email}
                    value={values.email.toLowerCase()}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.currentTarget.name]: e.currentTarget.value,
                      });
                      handleChange(e);
                    }}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                </>
              </Box>
              {/* BUTTONS */}
              <Box>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                  }}
                >
                  REGISTER
                </Button>
                <Typography
                  onClick={() => {
                    navigate("/");
                    resetForm();
                  }}
                  color="text.disabled"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Already have an account? Login here.
                </Typography>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Register;

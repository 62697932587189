// color design tokens export
export const tokensDark = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },

  primary: {
    // blue
    100: "#dee9f9",
    200: "#bcd4f2",
    300: "#9bbeec",
    400: "#79a9e5",
    500: "#5893df",
    600: "#4676b2",
    700: "#355886",
    800: "#233b59",
    900: "#121d2d",
  },

  secondary: {
    // teal
    100: "#d5f3f6",
    200: "#abe8ed",
    300: "#82dce5",
    400: "#58d1dc",
    500: "#2ec5d3",
    600: "#259ea9",
    700: "#1c767f",
    800: "#124f54",
    900: "#09272a",
  },
};

export const tokensLight = {
  primary: {
    // indigo (blue)
    100: "#d9dcf0",
    200: "#b2b9e1",
    300: "#8c97d3",
    400: "#6574c4",
    500: "#3f51b5",
    600: "#324191",
    700: "#26316d",
    800: "#192048",
    900: "#0d1024",
  },

  secondary: {
    // teal
    100: "#d5f3f6",
    200: "#abe8ed",
    300: "#82dce5",
    400: "#58d1dc",
    500: "#2ec5d3",
    600: "#259ea9",
    700: "#1c767f",
    800: "#124f54",
    900: "#09272a",
  },
};

// function that reverses the color palette
// function reverseTokens(tokensDark) {
//   const reversedTokens = {};
//   Object.entries(tokensDark).forEach(([key, val]) => {
//     const keys = Object.keys(val);
//     const values = Object.values(val);
//     const length = keys.length;
//     const reversedObj = {};
//     for (let i = 0; i < length; i++) {
//       reversedObj[keys[i]] = values[length - i - 1];
//     }
//     reversedTokens[key] = reversedObj;
//   });
//   return reversedTokens;
// }
// export const tokensLight = reverseTokens(tokensDark);

// mui theme settings

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: tokensLight.primary[500],
              light: tokensDark.primary[300],
              dark: tokensDark.primary[700],
            },
            secondary: {
              main: tokensDark.secondary[500],
              light: tokensDark.secondary[300],
              dark: tokensDark.secondary[700],
            },
            background: {
              default: "#192231",
              paper: "#24344d",
            },
            text: {
              primary: "#fff",
              secondary: "rgba(255,255,255,0.7)",
              disabled: "rgba(255, 255, 255, 0.5)",
              hint: "rgba(255, 255, 255, 0.5)",
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: tokensLight.primary[500],
              light: tokensLight.primary[300],
              dark: tokensLight.primary[700],
            },

            secondary: {
              main: tokensLight.secondary[500],
              light: tokensLight.secondary[300],
              dark: tokensLight.secondary[700],
            },

            background: {
              // default: "#fafafa",
              default: "#f5f5f5",
              paper: "#fff",
            },
            text: {
              primary: "rgba(0, 0, 0, 0.87)",
              secondary: "rgba(0, 0, 0, 0.54)",
              disabled: "rgba(0, 0, 0, 0.38)",
              hint: "rgba(0, 0, 0, 0.38)",
            },
          }),
    },

    typography: {
      // fontSize: "0.5rem",
      h1: {
        fontSize: "4rem",
      },
      h2: {
        fontSize: "3rem",
      },
      h3: {
        fontSize: "2.3rem",
      },
      h4: {
        fontSize: "1.8rem",
      },
      h5: {
        fontSize: "1.3rem",
      },
      h6: {
        fontSize: "1rem",
      },
      subtitle1: {
        fontSize: "0.7rem",
      },
      subtitle2: {
        fontSize: "0.4rem",
      },
    },
  };
};

import React from "react";
import { Box, Typography } from "@mui/material";
import FlexBetween from "./FlexBetween";

const StatBox = ({ title, value, increase, icon, description }) => {
  //const theme = useTheme();
  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={"background.paper"}
      borderRadius="0.55rem"
      sx={{ minWidth: 150 }}
    >
      <FlexBetween>
        <Typography variant="h6">{title}</Typography>
        {icon}
      </FlexBetween>

      <Typography variant="h3" fontWeight="600">
        {value}
      </Typography>
      <FlexBetween gap="1rem">
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: "secondary.light" }}
        >
          {increase}
        </Typography>
        <Typography variant="subtitle1">{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default StatBox;

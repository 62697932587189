import { useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader/Loader";
import { getBookings, updateBooking } from "redux/store/booking/bookingSlice";
import BookingFormik from "components/booking/BookingFormik";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";

const EditBooking = (props) => {
  const {
    id,
    bookingNumber,
    chaletName,
    startDate,
    endDate,
    nights,
    lastName,
    adults,
    children,
    baby,
    animals,
    type,
    cleaningHours,
    winterHours,
    summerHours,
    managementHours,
    bookingStatus,
    partner,
    closePopup,
  } = props;

  useRedirectLoggedOutUser("/");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();

  const handleClose = () => {
    closePopup();
  };

  const saveBooking = async (data) => {
    setIsLoading(true);

    const formData = {
      cleaningHours: data.cleaningHours,
      winterHours: data.winterHours,
      summerHours: data.summerHours,
      managementHours: data.managementHours,
    };

    let response = await dispatch(updateBooking({ id, formData }));

    if (response?.error?.message === "Rejected") {
      setIsLoading(false);
      navigate("/bookings");
    } else {
      setIsLoading(false);
      await dispatch(getBookings());
      navigate("/bookings");
      closePopup();
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Paper sx={{ p: 2, m: 2.5 }}>
        <BookingFormik
          bookingNumber={bookingNumber}
          chaletName={chaletName}
          startDate={startDate}
          endDate={endDate}
          nights={nights}
          lastName={lastName}
          adults={adults}
          children={children}
          baby={baby}
          animals={animals}
          type={type}
          cleaningHours={cleaningHours}
          winterHours={winterHours}
          summerHours={summerHours}
          managementHours={managementHours}
          bookingStatus={bookingStatus}
          partner={partner}
          saveBooking={saveBooking}
          handleClose={handleClose}
        />
      </Paper>
    </>
  );
};

export default EditBooking;

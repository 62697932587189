import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "redux/store/auth/authSlice";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import { useState } from "react";
import ConfirmDialog from "components/ConfirmDialog";
import { deleteInvoice, getInvoices } from "redux/store/invoice/invoiceSlice";
import moment from "moment/moment";
import EditBookingAction from "components/booking/EditBookingAction";
import PdfInVoiceAction from "components/invoice/PdfInvoiceAction";
import FlexBetween from "components/FlexBetween";
import { DateRangePicker } from "rsuite";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import removeTime from "components/utils/removeTime";

const predefinedBottomRanges = [
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "bottom",
    appearance: "default",
  },

  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Last 30 days",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -30),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -30),
      ];
    },
    appearance: "default",
  },
  {
    label: "Last 60 days",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -60),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -60),
      ];
    },
    appearance: "default",
  },
];

const Invoices = () => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [value, setValue] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const handleClick = () => {
    setValue([]);
    setFilteredInvoices(invoices);
  };

  const handleChange = () => {
    let filtered = allInvoices.filter((invoice) => {
      return (
        removeTime(new Date(invoice.invoiceDate)) >=
          removeTime(new Date(value[0])) &&
        removeTime(new Date(invoice.invoiceDate)) <=
          removeTime(new Date(value[1]))
      );
    });

    setFilteredInvoices(filtered);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { invoices, isLoading, isError, message } = useSelector(
    (state) => state.invoice
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getInvoices());
      setAllInvoices(invoices);
      setFilteredInvoices(invoices);
    }

    // useEffect(() => {
    //   setAllInvoices(invoices);
    //   setFilteredInvoices(invoices);
    // }, [invoices]);

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Nr.",
      flex: 0.35,
      editable: false,
      filterable: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice date",
      flex: 0.3,
      editable: false,
      filterable: false,
      renderCell: (params) =>
        moment(params.row.invoiceDate).format("DD-MM-YYYY"),
    },
    {
      field: "dueDate",
      headerName: "Due date",
      flex: 0.3,
      editable: false,
      filterable: false,
      renderCell: (params) => moment(params.row.dueDate).format("DD-MM-YYYY"),
    },
    {
      field: "total",
      headerName: "Total",
      flex: 0.2,
      editable: false,
      filterable: false,
      //renderCell: (params) => "€ " + params.row.total,
      // alternative works also
      valueFormatter: (params) => `€ ${params.value}`,
    },

    {
      field: "bookingNumber",
      headerName: "Booking",
      flex: 0.3,
      filterable: false,
      valueGetter: (params) => params.row.booking.bookingNumber,
    },
    {
      field: "chaletName",
      headerName: "Chalet",
      flex: 0.4,
      filterable: false,
    },
    {
      field: "Owner",
      headerName: "Owner",
      flex: 0.4,
      filterable: false,
      valueGetter: (params) =>
        params.row.owner.firstName + " " + params.row.owner.lastName,
    },

    {
      field: "invoice",
      headerName: "Invoice",
      type: "actions",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <>
            <PdfInVoiceAction {...{ params }} />
            {/* <EditBookingAction {...{ params }} />  */}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box m="1rem 1.5rem">
        <Box
          gridColumn="span 12"
          gridRow="span 1"
          backgroundColor="background.paper"
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h4" mb="1rem">
            List of Invoices
          </Typography>

          <FlexBetween>
            <DateRangePicker
              placeholder="Select Date Range"
              format="dd-MM-yyyy"
              showWeekNumbers
              editable={false}
              value={value}
              onChange={setValue}
              onExit={handleChange}
              cleanable={false}
              ranges={predefinedBottomRanges}
            />
            <Button color="secondary" variant="outlined" onClick={handleClick}>
              {/* <Button variant="outlined" onClick={handleClick}> */}
              All Invoices
            </Button>
          </FlexBetween>

          {/* <Box m="1.5rem 2.5rem"> */}
          {/* <Header title="List of Invoices" /> */}

          <Box
            height="75vh"
            gridColumn="span 12"
            gridRow="span 1"
            mt="1rem"
            borderRadius="0.55rem"
            backgroundColor="background.paper"
          >
            <DataGrid
              disableColumnSelector
              // loading={isLoading || !invoices}
              loading={isLoading || !filteredInvoices}
              getRowId={(row) => row._id}
              // rows={invoices || []}
              rows={filteredInvoices || []}
              columns={columns}
              disableSelectionOnClick={true}
            />
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Invoices;

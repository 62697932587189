import { createSlice } from "@reduxjs/toolkit";

const firstName = JSON.parse(localStorage.getItem("firstName"));
const role = JSON.parse(localStorage.getItem("role"));
const profileImagePath = JSON.parse(localStorage.getItem("profileImagePath"));
const uid = JSON.parse(localStorage.getItem("uid"));
const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

const initialState = {
  isLoggedIn: false,
  isAdmin: isAdmin ? isAdmin : false,
  //isAdmin: false,
  mode: "light",
  firstName: firstName ? firstName : "",
  role: role ? role : "",
  profileImagePath: profileImagePath ? profileImagePath : "",
  uid: uid ? uid : "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_MODE(state) {
      state.mode = state.mode === "light" ? "dark" : "light";
    },

    // Actions fired from frontend
    SET_LOGIN(state, action) {
      // console.log("SET_LOGIN:", action.payload);
      state.isLoggedIn = action.payload;
    },
    // Actions fired from frontend
    SET_LOGOUT(state, action) {
      //state = undefined;
      state = initialState;
    },

    // Actions fired from frontend
    SET_ADMIN(state, action) {
      localStorage.setItem("isAdmin", JSON.stringify(action.payload));
      state.isAdmin = action.payload;
    },

    SET_FIRSTNAME(state, action) {
      localStorage.setItem("firstName", JSON.stringify(action.payload));
      state.firstName = action.payload;
    },

    SET_ROLE(state, action) {
      localStorage.setItem("role", JSON.stringify(action.payload));
      state.role = action.payload;
    },

    SET_PROFILEIMAGEPATH(state, action) {
      localStorage.setItem("profileImagePath", JSON.stringify(action.payload));
      state.profileImagePath = action.payload;
    },

    SET_UID(state, action) {
      localStorage.setItem("uid", JSON.stringify(action.payload));
      state.uid = action.payload;
    },
  },
});

export const {
  SET_MODE,
  SET_LOGIN,
  SET_LOGOUT,
  SET_FIRSTNAME,
  // SET_USER,
  SET_ROLE,
  SET_PROFILEIMAGEPATH,
  SET_UID,
  SET_ADMIN,
} = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectFirstName = (state) => state.auth.firstName;
// export const selectUser = (state) => state.auth.user;
export const selectRole = (state) => state.auth.role;
export const selectUid = (state) => state.auth.uid;
export const selectProfileImagePath = (state) => state.auth.profileImagePath;
export const selectIsAdmin = (state) => state.auth.isAdmin;

export default authSlice.reducer;

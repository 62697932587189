import { Box, Typography, useMediaQuery } from "@mui/material";
import Calendar from "./Calendar";
import StatBox from "components/StatBox";
import { PersonAdd } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import NightShelterIcon from "@mui/icons-material/NightShelter";

const BookingsStats = ({ bookings, isLoading }) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <>
      <Box m="1rem 1.5rem">
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Total Bookings"
            //   value={data && data.totalCustomers}
            value={40}
            // increase="+14%"
            // description="this year 2022"
            icon={
              <NightShelterIcon
                sx={{ color: theme.palette.primary.light, fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="Bookings this Week"
            // value={data && data.thisMonthStats.totalSales}
            value={20}
            // increase="+5%"
            // description="Since last month"
            icon={
              <NightShelterIcon
                sx={{ color: theme.palette.primary.light, fontSize: "26px" }}
              />
            }
          />

          <Box
            gridColumn="span 8"
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Typography
              variant="h6"
              // sx={{ color: theme.palette.secondary[100] }}
            >
              Heatmap Bookings pro Day
            </Typography>
            <Calendar />
          </Box>

          <StatBox
            title="Beds this Week"
            value={200}
            // increase="+13%"
            // description="Since last week"
            icon={
              <BedIcon
                sx={{ color: theme.palette.primary.light, fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title="FTE This Month"
            //   value={data && data.todayStats.totalSales}
            value={4}
            // increase="+21%"
            // description="Since last month"
            icon={
              <PersonAdd
                sx={{ color: theme.palette.primary.light, fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default BookingsStats;

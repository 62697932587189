import { Box, IconButton, Tooltip } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import EditChalet from "components/chalet/EditChalet";
import Popup from "components/Popup";
import { useDispatch } from "react-redux";
import { getChalets } from "redux/store/chalet/chaletSlice";

const ChaletActions = ({ params, handleDelete }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleEdit = () => {
    setOpenPopup(true);
  };

  const handleSave = () => {
    setOpenPopup(false);
    dispatch(getChalets());
  };

  const navigate = useNavigate();

  const handleView = () => {
    navigate("/chalet", {
      state: {
        chaletId: params.row._id,
      },
    });
  };

  return (
    <>
      <Box>
        <Tooltip title="View this chalet">
          <IconButton size="small" color="info" onClick={handleView}>
            <VisibilityRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit this chalet">
          <IconButton size="small" color="info" onClick={handleEdit}>
            <EditRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete this chalet">
          <IconButton
            size="small"
            color="error"
            onClick={() => {
              handleDelete(params.row._id);
            }}
          >
            <DeleteForeverRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popup
        title="Edit Chalet Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditChalet
          id={params.row?._id}
          chaletName={params.row?.chaletName}
          chaletNumber={params.row?.chaletNumber}
          address={params.row?.address}
          zipcode={params.row?.zipcode}
          city={params.row?.city}
          country={params.row?.country}
          mainImage={params.row?.mainImage}
          closePopup={closePopup}
          handleSave={handleSave}
        />
      </Popup>
    </>
  );
};

export default ChaletActions;

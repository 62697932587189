import React, { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "redux/store/auth/authSlice";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import { deleteChalet, getChalets } from "redux/store/chalet/chaletSlice";
import ChaletActions from "../../components/chalet/ChaletActions";
import { useState } from "react";
import ConfirmDialog from "components/ConfirmDialog";

const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const Chalets = () => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { chalets, isLoading, isError, message } = useSelector(
    (state) => state.chalet
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getChalets());
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  const delChalet = async (id) => {
    // console.log(id);
    await dispatch(deleteChalet(id));
    await dispatch(getChalets());
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        // console.log("dialog clicked for ID:", id);
        delChalet(id);
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const columns = [
    //   const columns = useMemo(
    //     () => [

    {
      field: "mainImage",
      headerName: "Photo",
      width: 70,
      renderCell: (params) => (
        <Avatar
          //src="{SERVER_URL}{params.row.mainImage.filePath}"
          src={`${SERVER_URL}${params.row.mainImage.filePath}`}
          variant="rounded"
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "chaletName",
      headerName: "Chalet Name",
      flex: 1,
      editable: false,
      filterable: false,
    },
    {
      field: "chaletNumber",
      headerName: "Chalet Number",
      flex: 0.4,
      filterable: false,
    },
    {
      field: "Owner",
      headerName: "Owner",
      flex: 1,
      filterable: false,
      valueGetter: (params) =>
        params.row.user.firstName + " " + params.row.user.lastName,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <ChaletActions {...{ params, handleDelete }} />
          </>
        );
      },
    },
  ];
  // [rowId]
  //   );

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="List of Chalets" />

        <Box
          height="50vh"
          gridColumn="span 12"
          gridRow="span 1"
          mt="1rem"
          borderRadius="0.55rem"
          backgroundColor="background.paper"
        >
          <DataGrid
            disableColumnSelector
            loading={isLoading || !chalets}
            getRowId={(row) => row._id}
            rows={chalets || []}
            columns={columns}
            disableSelectionOnClick={true}
          />
        </Box>
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Chalets;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import bookingService from "./bookingService";

const initialState = {
  bookings: [],
  chaletBookings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// get All bookings
export const getBookings = createAsyncThunk(
  "bookings/getAll",
  async (_, thunkAPI) => {
    try {
      return await bookingService.getBookings();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get specific chalet bookings
export const getChaletBookings = createAsyncThunk(
  "bookings/getChaletBookings",
  async (id, thunkAPI) => {
    try {
      return await bookingService.getChaletBookings(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// UPDATE BOOKING
export const updateBooking = createAsyncThunk(
  "bookings/updateBooking",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await bookingService.updateBooking(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    CLEAR_BOOKINGS(state, action) {
      state.bookings = undefined;
      state.chaletBookings = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBookings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        //console.log(action.payload);
        state.bookings = action.payload;
      })
      .addCase(getBookings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getChaletBookings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChaletBookings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        //console.log("action payload:", action.payload);
        state.chaletBookings = action.payload;
      })
      .addCase(getChaletBookings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateBooking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Booking updated successfully");
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { CLEAR_BOOKINGS } = bookingSlice.actions;
export const selectIsLoading = (state) => state.user.isLoading;

export default bookingSlice.reducer;

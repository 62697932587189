import { Box, Tab } from "@mui/material";

import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { ReceiptLongOutlined } from "@mui/icons-material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ChaletBookingsList from "./ChaletBookingsList";
import ChaletContracts from "./ChaletContracts";
import { useState } from "react";

// const ChaletInfo = ({ chalet, isLoading }) => {
const ChaletTabs = (chaletId) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="1rem" backgroundColor="background.paper" sx={{ flexGrow: 1 }}>
      <TabContext value={value}>
        {/* <TabContext> */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab icon={<HotelOutlinedIcon />} label="BOOKINGS" value="1" />
            <Tab icon={<ReceiptLongOutlined />} label="CONTRACT" value="2" />
            <Tab icon={<InventoryOutlinedIcon />} label="INVENTORY" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ChaletBookingsList chaletId={chaletId} />
        </TabPanel>
        <TabPanel value="2">
          <ChaletContracts />
        </TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </Box>
  );
};

export default ChaletTabs;

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import DateRangePicker from "rsuite/DateRangePicker";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import FlexBetween from "components/FlexBetween";
import EditBookingAction from "components/booking/EditBookingAction";
import EditInvoiceAction from "components/booking/EditInvoiceAction";
import removeTime from "components/utils/removeTime";

const predefinedBottomRanges = [
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "bottom",
    appearance: "default",
  },

  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next 14 days",
    value: [new Date(), addDays(new Date(), 14)],
    appearance: "default",
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 30)],
    appearance: "default",
  },
];

const BookingsList = ({ bookings, isLoading }) => {
  const [allBookings, setAllBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);

  const [value, setValue] = useState([]);

  useEffect(() => {
    setAllBookings(bookings);
    setFilteredBookings(bookings);
  }, [bookings]);

  const handleClick = () => {
    setValue([]);
    setFilteredBookings(bookings);
  };

  const handleChange = () => {
    let filtered = allBookings.filter((booking) => {
      let startDate = removeTime(new Date(booking["startDate"]));
      let endDate = removeTime(new Date(booking["endDate"]));
      return (
        startDate >= removeTime(new Date(value[0])) &&
        endDate <= removeTime(new Date(value[1]))
      );
    });

    setFilteredBookings(filtered);
  };

  // const isNonMediumScreens = useMediaQuery("(min-width: 1100px)");

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      filterable: false,
    },
    {
      field: "chaletId",
      headerName: "ChaletId",
      flex: 0.5,
      //   renderCell: (params) => params.row.chalets.chaletName,
      valueGetter: (params) => params.row?.chalet?._id,
      type: "string",
      filterable: false,
      editable: false,
    },
    {
      field: "bookingNumber",
      headerName: "Booking Nr.",
      flex: 0.5,
      type: "string",
      filterable: false,
      editable: false,
    },
    {
      field: "chaletName",
      headerName: "Chalet Name",
      flex: 0.5,
      //   renderCell: (params) => params.row.chalets.chaletName,
      valueGetter: (params) => params.row?.chalet?.chaletName,
      type: "string",
      filterable: false,
      editable: false,
    },
    {
      field: "chaletNumber",
      headerName: "Chalet",
      flex: 0.2,
      //   renderCell: (params) => params.row.chalets.chaletName,
      valueGetter: (params) => params.row?.chalet?.chaletNumber,
      //   type: "Number",
      filterable: false,
    },

    {
      field: "startDate",
      headerName: "Check In",
      // width: 200,
      renderCell: (params) => moment(params.row.startDate).format("DD-MM-YYYY"),
      flex: 0.4,
      filterable: false,
    },
    {
      field: "endDate",
      headerName: "Check Out",
      renderCell: (params) => moment(params.row.endDate).format("DD-MM-YYYY"),
      flex: 0.4,
      filterable: false,
    },
    {
      field: "cleaningHours",
      headerName: "Cleaning H",
      flex: 0.2,
      filterable: false,
    },

    {
      field: "winterHours",
      headerName: "Winter H",
      flex: 0.2,
      filterable: false,
    },

    {
      field: "summerHours",
      headerName: "Summer H",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "managementHours",
      headerName: "Manmgt. H",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "adults",
      headerName: "Adults",
      flex: 0.2,
    },
    {
      field: "children",
      headerName: "Children",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "baby",
      headerName: "Baby",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "animals",
      headerName: "Animals",
      flex: 0.2,
      filterable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
      filterable: false,
    },
    {
      field: "bookingStatus",
      headerName: "Status",
      flex: 0.3,
      filterable: true,
    },
    {
      field: "invoice",
      headerName: "Invoice",
      type: "actions",
      flex: 0.5,
      // renderCell: (params) => <BookingActions {...{ params }} />,

      renderCell: (params) => {
        if (params.row.bookingStatus === "Open") {
          return "";
        } else if (
          params.row.bookingStatus === "Closed" &&
          params.row.cleaningHours !== 0
        ) {
          return (
            <>
              <EditInvoiceAction {...{ params }} />
              <EditBookingAction {...{ params }} />
            </>
          );
        } else if (
          params.row.bookingStatus === "Closed" &&
          params.row.cleaningHours === 0
        ) {
          return (
            <>
              <EditBookingAction {...{ params }} />
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <Box m="1rem 1.5rem">
        <Box
          gridColumn="span 12"
          gridRow="span 1"
          backgroundColor="background.paper"
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" mb="1rem">
            List of Bookings
          </Typography>

          <FlexBetween>
            <DateRangePicker
              placeholder="Select Date Range"
              format="dd-MM-yyyy"
              showWeekNumbers
              editable={false}
              value={value}
              onChange={setValue}
              onExit={handleChange}
              cleanable={false}
              ranges={predefinedBottomRanges}
            />
            <Button color="secondary" variant="outlined" onClick={handleClick}>
              {/* <Button variant="outlined" onClick={handleClick}> */}
              All Bookings
            </Button>
          </FlexBetween>
          <Box
            // height="42vh"
            height="52vh"
            gridColumn="span 12"
            gridRow="span 1"
            backgroundColor="background.paper"
            mt="1rem"
            borderRadius="0.55rem"
            sx={{
              // height: 300,
              //width: "100%",
              "& .green": {
                backgroundColor: "lightgreen",
                color: "#1a3e72",
              },

              "& .red": {
                backgroundColor: "red",

                // color: "#1a3e72",
                color: "white",
              },
              "& .orange": {
                backgroundColor: "orange",

                // color: "#1a3e72",
                color: "white",
              },
            }}
          >
            <DataGrid
              disableColumnSelector
              loading={isLoading || !filteredBookings}
              getRowId={(row) => row._id}
              rows={filteredBookings || []}
              getCellClassName={(params) => {
                if (
                  params.field === "bookingStatus" ||
                  params.value === "Open"
                ) {
                  if (params.value === "Open") {
                    return "";
                  }
                  if (params.value === "Closed") {
                    if (params.row.cleaningHours === 0) {
                      return "orange";
                    }
                    return "red";
                  }
                  if (params.value === "Invoiced") {
                    return "green";
                  }
                }
              }}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns _id, the other columns will remain visible
                    _id: false,
                    chaletId: false,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingsList;

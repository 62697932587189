import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/store/auth/authSlice";
import userReducer from "../redux/store/user/userSlice";
import bookingReducer from "../redux/store/booking/bookingSlice";
import chaletReducer from "../redux/store/chalet/chaletSlice";
import invoiceReducer from "../redux/store/invoice/invoiceSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  // if you do not want to persist this part of the state
  //blacklist: ["userService.reducerPath", "bookingService.reducerPath"],
};

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  booking: bookingReducer,
  chalet: chaletReducer,
  invoice: invoiceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // to prevent from error A non-serializable value was detected in an action, in the path: `register
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

export { persistor };

import React, { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "redux/store/auth/authSlice";
import { getUsers } from "redux/store/user/userSlice";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";

const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const Users = () => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const { users, isLoading, isError, message } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getUsers());
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "profileImage",
      headerName: "Photo",
      width: 70,
      renderCell: (params) => (
        <Avatar
          src={`${SERVER_URL}${params.row.profileImage.filePath}`}
          variant="rounded"
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.5,
      filterable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
      filterable: false,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      filterable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.5,
      filterable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
      filterable: false,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="List of owners" />

      <Box
        height="50vh"
        gridColumn="span 12"
        gridRow="span 1"
        mt="1rem"
        borderRadius="0.55rem"
        backgroundColor="background.paper"
      >
        <DataGrid
          disableColumnSelector
          loading={isLoading || !users}
          getRowId={(row) => row._id}
          rows={users || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Users;

import axios from "axios";

//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const API_URL = `${SERVER_URL}/api/bookings/`;

// Get all Bookings
const getBookings = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Get bookings from a specific chalet
const getChaletBookings = async (id) => {
  const response = await axios.get(API_URL + id);
  //console.log("Response data getChaletBookings:", response.data);
  return response.data;
};

// UPDATE BOOKING
const updateBooking = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const bookingService = {
  getBookings,
  getChaletBookings,
  updateBooking,
};

export default bookingService;

import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomProvider } from "rsuite";
import axios from "axios";

import Layout from "pages/layout/Layout";
import Users from "pages/user/Users";
import Bookings from "pages/booking/Bookings";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import AddChalet from "pages/addChalet/AddChalet";
import Chalets from "pages/chalets/Chalets";
import ChaletDetail from "pages/chalets/ChaletDetail";
import Invoices from "pages/invoices/Invoices";
// import Invoice from "pages/editInvoice/EditInvoice";

// widthCredentials returns the cookie from the server
axios.defaults.withCredentials = true;

function App() {
  const mode = useSelector((state) => state.auth.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ToastContainer autoClose={2000} />
        <ThemeProvider theme={theme}>
          <CustomProvider theme={mode}>
            {/* reset the CSS */}
            <CssBaseline />
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Login />} />
                <Route path="/chalet" element={<ChaletDetail />} />
                <Route path="/chalets" element={<Chalets />} />
                <Route path="/add-chalet" element={<AddChalet />} />
                <Route path="/users" element={<Users />} />
                <Route path="/bookings" element={<Bookings />} />
                <Route path="/invoices" element={<Invoices />} />
              </Route>
            </Routes>
          </CustomProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { Box, IconButton, Tooltip } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useState } from "react";
import Popup from "components/Popup";
import { useDispatch } from "react-redux";

const PdfInVoiceAction = ({ params }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const closePopup = () => {
    setOpenPopup(false);
  };

  const handlePdfInvoice = () => {
    setOpenPopup(true);
  };

  //   const handleSave = () => {
  //     setOpenPopup(false);
  //     dispatch(getBookings());
  //   };

  return (
    <>
      <Box>
        <Tooltip title="Create PDF Invoice">
          <IconButton size="small" color="info" onClick={handlePdfInvoice}>
            <DescriptionOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Popup
        title="Invoice PDF Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        {/* <EditInvoice
          bookingId={params.row._id}
          chaletId={params.row?.chalet?._id}
          bookingNumber={params.row.bookingNumber}
          chaletName={params.row?.chalet?.chaletName}
          firstName={params.row?.chalet?.user?.firstName}
          lastName={params.row?.chalet?.user?.lastName}
          address={params.row?.chalet?.address}
          zipcode={params.row?.chalet?.zipcode}
          city={params.row?.chalet?.city}
          country={params.row?.chalet?.country}
          startDate={params.row?.startDate}
          endDate={params.row?.endDate}
          adults={params.row?.adults}
          children={params.row?.children}
          baby={params.row?.baby}
          animals={params.row?.animals}
          summerHours={params.row?.summerHours}
          winterHours={params.row?.winterHours}
          cleaningHours={params.row?.cleaningHours}
          managementHours={params.row?.managementHours}
          closePopup={closePopup}
          handleSave={handleSave}
        /> */}
      </Popup>
    </>
  );
};

export default PdfInVoiceAction;

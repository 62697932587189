import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import chaletService from "./chaletService";

const initialState = {
  mychalet: null,
  chalets: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET CHALET BY ID
export const getChaletById = createAsyncThunk(
  "chalets/getChaletById",
  async (id, thunkAPI) => {
    try {
      return await chaletService.getChaletById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET CHALETS
export const getChalets = createAsyncThunk(
  "chalets/getChalets",
  async (_, thunkAPI) => {
    try {
      return await chaletService.getChalets();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// CREATE NEW CHALET
export const createChalet = createAsyncThunk(
  "chalets/create",
  async (formData, thunkAPI) => {
    try {
      return await chaletService.createChalet(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// UPDATE CHALET
export const updateChalet = createAsyncThunk(
  "chalets/updateChalet",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await chaletService.updateChalet(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE CHALET
export const deleteChalet = createAsyncThunk(
  "chalets/delete",
  async (id, thunkAPI) => {
    try {
      return await chaletService.deleteChalet(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const chaletSlice = createSlice({
  name: "chalet",
  initialState,
  reducers: {
    CLEAR_CHALETS(state, action) {
      state.mychalet = undefined;
      state.chalets = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChaletById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChaletById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.mychalet = action.payload;
        // state.chalets = action.payload;
      })
      .addCase(getChaletById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getChalets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChalets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.chalets = action.payload;
      })
      .addCase(getChalets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(createChalet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createChalet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.chalets.push(action.payload);
        toast.success("Chalet added successfully");
      })
      .addCase(createChalet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateChalet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateChalet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Chalet updated successfully");
      })
      .addCase(updateChalet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // toast.error(action.error);
      })
      .addCase(deleteChalet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteChalet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Chalet deleted successfully");
      })
      .addCase(deleteChalet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { CLEAR_CHALETS } = chaletSlice.actions;

export const selectIsLoading = (state) => state.user.isLoading;
export const selectChalet = (state) => state.chalet.mychalet;

export default chaletSlice.reducer;

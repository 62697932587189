export const calendarData = [
  {
    value: 2,
    day: "2023-01-20",
  },
  {
    value: 4,
    day: "2023-02-24",
  },
  {
    value: 1,
    day: "2022-12-17",
  },
  {
    value: 4,
    day: "2023-02-17",
  },
  {
    value: 1,
    day: "2023-06-23",
  },
  {
    value: 3,
    day: "2022-12-23",
  },
  {
    value: 1,
    day: "2023-08-11",
  },
  {
    value: 3,
    day: "2022-12-30",
  },
  {
    value: 1,
    day: "2022-02-25",
  },
  {
    value: 1,
    day: "2022-02-18",
  },
  {
    value: 1,
    day: "2022-03-11",
  },
  {
    value: 1,
    day: "2022-10-07",
  },
  {
    value: 2,
    day: "2023-01-06",
  },
  {
    value: 1,
    day: "2022-07-01",
  },
  {
    value: 2,
    day: "2023-01-13",
  },
  {
    value: 1,
    day: "2023-04-18",
  },
  {
    value: 1,
    day: "2022-07-08",
  },
  {
    value: 1,
    day: "2023-04-26",
  },
  {
    value: 1,
    day: "2023-12-22",
  },
  {
    value: 1,
    day: "2023-04-21",
  },
  {
    value: 3,
    day: "2023-03-03",
  },
  {
    value: 1,
    day: "2023-08-18",
  },
  {
    value: 1,
    day: "2022-12-21",
  },
  {
    value: 1,
    day: "2023-07-07",
  },
  {
    value: 1,
    day: "2022-01-28",
  },
  {
    value: 1,
    day: "2022-12-15",
  },
  {
    value: 1,
    day: "2023-04-27",
  },
  {
    value: 1,
    day: "2022-03-04",
  },
  {
    value: 1,
    day: "2023-03-17",
  },
  {
    value: 3,
    day: "2023-02-03",
  },
  {
    value: 1,
    day: "2023-05-12",
  },
  {
    value: 1,
    day: "2022-12-14",
  },
  {
    value: 3,
    day: "2023-02-10",
  },
  {
    value: 1,
    day: "2022-12-16",
  },
  {
    value: 1,
    day: "2023-06-05",
  },
  {
    value: 1,
    day: "2022-11-30",
  },
  {
    value: 1,
    day: "2022-02-11",
  },
  {
    value: 1,
    day: "2023-06-06",
  },
  {
    value: 1,
    day: "2023-06-28",
  },
  {
    value: 1,
    day: "2023-03-31",
  },
  {
    value: 1,
    day: "2023-10-20",
  },
  {
    value: 2,
    day: "2023-01-27",
  },
  {
    value: 1,
    day: "2023-08-04",
  },
];

import axios from "axios";

//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const API_URL = `${SERVER_URL}/api/chalets/`;

// GET CHALET BY ID
const getChaletById = async (id) => {
  const response = await axios.get(`${API_URL}${id}`);
  return response.data;
};

// GET ALL CHALETS
const getChalets = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// CREATE NEW CHALET
const createChalet = async (formData) => {
  const response = await axios.post(API_URL, formData);
  return response.data;
};

// UPDATE CHALET
const updateChalet = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

// DELETE CHALET
const deleteChalet = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};

const chaletService = {
  getChaletById,
  getChalets,
  createChalet,
  updateChalet,
  deleteChalet,
};

export default chaletService;

import { useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChaletFormik from "components/chalet/ChaletFormik";
import { getChalets, updateChalet } from "../../redux/store/chalet/chaletSlice";
import Loader from "components/loader/Loader";

const EditChalet = (props) => {
  const {
    id,
    chaletName,
    chaletNumber,
    address,
    zipcode,
    city,
    country,
    mainImage,
    closePopup,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updMainImage, setUpdMainImage] = useState("");
  const [mainImagePreview, setMainImagePreview] = useState(mainImage);
  const [isLoading, setIsLoading] = useState();

  const handleClose = () => {
    closePopup();
  };

  const handleImageChange = (acceptedFiles) => {
    setUpdMainImage(acceptedFiles);
    setMainImagePreview(acceptedFiles[0]);
  };

  const saveChalet = async (data) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("chaletName", data.chaletName);
    formData.append("chaletNumber", data.chaletNumber);
    formData.append("address", data.address);
    formData.append("zipcode", data.zipcode);
    formData.append("city", data.city);
    formData.append("country", data.country);
    if (updMainImage) {
      formData.append("mainImage", data.mainImage);
    }

    // console.log("Formdata:", ...formData);

    let response = await dispatch(updateChalet({ id, formData }));

    if (response?.error?.message === "Rejected") {
      setIsLoading(false);
      navigate("/add-chalet");
    } else {
      setIsLoading(false);
      await dispatch(getChalets());
      closePopup();
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Paper sx={{ p: 2, m: 2.5 }}>
        <ChaletFormik
          mainImage={mainImage}
          mainImagePreview={mainImagePreview}
          chaletName={chaletName}
          chaletNumber={chaletNumber}
          address={address}
          zipcode={zipcode}
          city={city}
          country={country}
          handleImageChange={handleImageChange}
          saveChalet={saveChalet}
          handleClose={handleClose}
        />
      </Paper>
    </>
  );
};

export default EditChalet;

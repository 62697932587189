import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";

import FlexBetween from "./FlexBetween";
import { useDispatch, useSelector } from "react-redux";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "services/authService";

import {
  selectFirstName,
  selectProfileImagePath,
  SET_ADMIN,
  SET_FIRSTNAME,
  SET_LOGIN,
  SET_LOGOUT,
  SET_MODE,
  SET_PROFILEIMAGEPATH,
  SET_ROLE,
  SET_UID,
} from "redux/store/auth/authSlice";

import { ShowOnLogin } from "./protect/HiddenLinks";
import { CLEAR_BOOKINGS } from "redux/store/booking/bookingSlice";
import { CLEAR_CHALETS } from "redux/store/chalet/chaletSlice";
import { CLEAR_USERS } from "redux/store/user/userSlice";
import { persistor } from "redux/store";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const firstName = useSelector(selectFirstName);
  const profileImagePath = useSelector(selectProfileImagePath);

  const SERVER_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_API_URL
      : process.env.REACT_APP_DEV_API_URL;

  // open / close dropdown menu
  // const [anchorEl, setAnchorEl] = useState(null);
  //const isOpen = Boolean(anchorEl);
  //const handleClick = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    await logoutUser();
    // toegevoegd 15-2 om te testen
    persistor.purge();
    dispatch(SET_LOGOUT());
    dispatch(SET_LOGIN(false));
    dispatch(SET_ADMIN(false));

    dispatch(SET_UID(""));
    dispatch(SET_FIRSTNAME(""));
    dispatch(SET_ROLE(""));
    dispatch(SET_PROFILEIMAGEPATH(""));

    dispatch(CLEAR_BOOKINGS());
    dispatch(CLEAR_CHALETS());
    dispatch(CLEAR_USERS());

    toast.success("User Logout Successfully");

    //handleClose();
    setIsSidebarOpen(!isSidebarOpen);
    navigate("/");
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}

        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* Toggle between dark and light mode */}
          <IconButton onClick={() => dispatch(SET_MODE())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>

          <FlexBetween>
            <ShowOnLogin>
              {/* <Avatar alt="Profile" src={profileImagePath} /> */}
              <Avatar alt="Profile" src={`${SERVER_URL}${profileImagePath}`} />

              <Box textAlign="left" padding="1rem">
                <Typography fontSize="0.75rem" sx={{ color: "text.primary" }}>
                  Welcome,
                </Typography>
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: "text.primary" }}
                >
                  {firstName}
                </Typography>
                {/* <Typography fontSize="0.75rem">{user.role}</Typography> */}
              </Box>
              <Button
                color="secondary"
                onClick={handleLogout}
                variant="outlined"
              >
                LOGOUT
              </Button>
            </ShowOnLogin>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

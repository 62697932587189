import { useEffect } from "react";
import { getBookings } from "redux/store/booking/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import { selectIsLoggedIn } from "redux/store/auth/authSlice";
import BookingsList from "./BookingsList";
import BookingsStats from "./BookingsStats";
import { Box } from "@mui/material";

const Bookings = () => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const { bookings, isLoading, isError, message } = useSelector(
    (state) => state.booking
  );

  useEffect(() => {
    // console.log("isLoggedIn in bookings.jsx:", isLoggedIn);
    if (isLoggedIn === true) {
      dispatch(getBookings());
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <Box>
      <BookingsStats />
      <BookingsList bookings={bookings} isLoading={isLoading} />
    </Box>
  );
};

export default Bookings;

import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import React, { useEffect, useState } from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { initialState } from "components/invoice/InitialState";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createInvoice } from "redux/store/invoice/invoiceSlice";
import Loader from "components/loader/Loader";

const EditInvoice = (props) => {
  const {
    bookingId,
    chaletId,
    bookingNumber,
    chaletName,
    firstName,
    lastName,
    address,
    zipcode,
    city,
    country,
    startDate,
    endDate,
    adults,
    children,
    baby,
    animals,
    summerHours,
    winterHours,
    cleaningHours,
    managementHours,
    closePopup,
    handleSave,
  } = props;

  useRedirectLoggedOutUser("/");

  const handleClose = () => {
    closePopup();
  };

  const [isLoading, setIsLoading] = useState();
  const dispatch = useDispatch();
  const [cleaningPrice, setCleaningPrice] = useState("265");
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [vat1, setVat1] = useState(0);
  const [vat2, setVat2] = useState(0);

  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    today.getTime() + 14 * 24 * 60 * 60 * 1000
  );

  useEffect(() => {
    setInvoiceData((prevState) => ({
      // ...prevState,
      items: [
        // ...prevState.items,
        {
          itemName: "House Cleaning / welcome",
          unitPrice: cleaningPrice,
          quantity: "1",
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "House Cleaning / welcome",
          unitPrice: cleaningPrice,
          quantity: "-1",
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "Bed linen - Washing (persons) ",
          unitPrice: "18",
          quantity: Number(adults) + Number(children),
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "Bed linen - Washing (persons) ",
          unitPrice: "18",
          quantity: -(Number(adults) + Number(children)),
          discount: "",
          amount: "",
          rates: "20",
        },

        {
          itemName: "Winter service (hours)",
          unitPrice: 25,
          quantity: winterHours,
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "Summer service (hours)",
          unitPrice: "25",
          quantity: summerHours,
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "Management service (hours)",
          unitPrice: "25",
          quantity: managementHours,
          discount: "",
          amount: "",
          rates: "20",
        },
        {
          itemName: "Animals",
          unitPrice: "15",
          quantity: animals,
          discount: "",
          amount: "",
          rates: "20",
        },
      ],
    }));

    // eslint-disable-next-line
  }, [winterHours]);

  useEffect(() => {
    //Get the subtotal
    const subVat1 = () => {
      var ratesArr = document.getElementsByName("rates");
      var amountArr = document.getElementsByName("amount");
      var subVat1 = 0;

      for (var i = 0; i < ratesArr.length; i++) {
        if (ratesArr[i].value === "10") {
          subVat1 += (+ratesArr[i].value / 100) * amountArr[i].value;

          console.log("arr rate:", ratesArr[i].value);
          console.log("arr amount:", amountArr[i].value);
        }
        // document.getElementById("subtotal").value = subtotal;
        setVat1(Number(subVat1).toFixed(2));
      }
    };

    subVat1();
  }, [invoiceData]);

  useEffect(() => {
    //Get the subtotal
    const subVat2 = () => {
      var ratesArr = document.getElementsByName("rates");
      var amountArr = document.getElementsByName("amount");
      var subVat2 = 0;

      for (var i = 0; i < ratesArr.length; i++) {
        if (ratesArr[i].value === "20") {
          subVat2 += (+ratesArr[i].value / 100) * amountArr[i].value;

          // console.log("arr rate:", ratesArr[i].value);
          // console.log("arr amount:", amountArr[i].value);
        }
        // document.getElementById("subtotal").value = subtotal;
        setVat2(Number(subVat2).toFixed(2));
      }
    };

    subVat2();
  }, [invoiceData]);

  useEffect(() => {
    //Get the subtotal
    const subTotal = () => {
      var arr = document.getElementsByName("amount");
      var subtotal = 0;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value) {
          subtotal += +arr[i].value;
        }
        // document.getElementById("subtotal").value = subtotal;
        setSubTotal(subtotal);
      }
    };

    subTotal();
  }, [invoiceData]);

  useEffect(() => {
    const total = () => {
      const overallSum = Number(vat1) + Number(vat2) + Number(subTotal);
      setTotal(Number(overallSum).toFixed(2));
    };
    total();
  }, [invoiceData, subTotal, vat1, vat2]);

  const handleAddField = (e) => {
    e.preventDefault();
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        { itemName: "", unitPrice: "", quantity: "", discount: "", amount: "" },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    console.log("handleRemoveField clicked");
    const values = invoiceData.items;
    values.splice(index, 1);
    setInvoiceData((prevState) => ({ ...prevState, values }));
  };

  const handleChange = (index, e) => {
    const values = [...invoiceData.items];
    values[index][e.target.name] = e.target.value;
    setInvoiceData({ ...invoiceData, items: values });
  };

  const handleSaveInvoice = async (index, e) => {
    setIsLoading(true);

    const formData = {
      chaletId: chaletId,
      chaletName: chaletName,
      owner: {
        firstName: firstName,
        lastName: lastName,
        address: address,
        zipcode: zipcode,
        city: city,
        country: country,
      },
      booking: {
        bookingId: bookingId,
        bookingNumber: bookingNumber,
        startDate: startDate,
        endDate: endDate,
        adults: adults,
        baby: baby,
        animals: animals,
      },
      summerHours,
      winterHours,
      cleaningHours,
      managementHours,
      invoiceNumber:
        moment(selectedDate).format("YYYYMM") + "-" + bookingNumber,
      dueDate: moment(selectedDate).format("YYYY-MM-DD"),
      invoiceDate: moment().format("YYYY-MM-DD"),
      items: [...invoiceData.items],
      subTotal,
      vat1,
      vat2,
      total,
    };

    await dispatch(createInvoice(formData));

    setIsLoading(false);
    handleSave();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box backgroundColor="background.paper" sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} m="2">
            <Paper sx={{ p: 2, m: 1 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  mb="1.5rem"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    // mb="1.5rem"
                  >
                    <Typography color="red" variant="h4">
                      DBW
                    </Typography>
                    <Typography variant="h4"> {" - "} Facility</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    // mt="2.5rem"
                  >
                    <Typography variant="body2">Donnersbachwald 241</Typography>
                    <Typography variant="body2">
                      8953 Irdning - Donnersbachtal
                    </Typography>
                    <Typography variant="body2">Oostenrijk</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h5">INVOICE</Typography>
                  <Box mt="1rem">
                    <Typography variant="body2">
                      Invoice: {moment(selectedDate).format("YYYYMM")}-
                      {bookingNumber}
                    </Typography>
                    <Typography variant="body2">
                      Date: {moment().format("DD.MM.YYYY")}
                    </Typography>
                    <Typography variant="body2">
                      Due date: {moment(selectedDate).format("DD.MM.YYYY")}
                    </Typography>
                    <Typography variant="body2">UID: xxx.xxx</Typography>
                    <Typography variant="body2">
                      IBAN xxx.xxx.xxx.xxx
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                // mb="1.5rem"
                // mt="1.5rem"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  mb="1.5rem"
                >
                  <Box mb="1rem">
                    <Typography variant="caption">BILL TO</Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <Typography variant="h6">
                      {/* {chalet?.user?.firstName} {chalet?.user?.lastName} */}
                      {firstName} {lastName}
                    </Typography>
                    <Typography variant="h6">{address}</Typography>
                    <Typography variant="h6">
                      {zipcode} {city}
                    </Typography>
                    <Typography variant="h6">{country}</Typography>
                    {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
                  </Box>
                </Box>

                <Box
                  mt="2rem"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="right"
                >
                  <Typography variant="body2">{chaletName}</Typography>
                  <Typography variant="body2">
                    Booking Nr: {bookingNumber}
                  </Typography>
                  <Typography variant="body2">
                    Period: {moment(startDate).format("DD.MM.YYYY")} {" - "}
                    {moment(endDate).format("DD.MM.YYYY")}
                  </Typography>
                </Box>
              </Box>

              <Box
                mt="1rem"
                backgroundColor="background.paper"
                sx={{ flexGrow: 1 }}
              >
                <TableContainer component={Paper}>
                  {/* <Table aria-label="Invoice table"> */}
                  <Table
                    sx={{ minWidth: 700 }}
                    size="small"
                    aria-label="Invoice table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Disc(%)</TableCell>
                        <TableCell>Vat(%)</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.items.map((itemField, index) => (
                        <TableRow key={index}>
                          <TableCell scope="row" style={{ width: "30%" }}>
                            {/* {" "} */}
                            <InputBase
                              style={{ width: "100%" }}
                              outline="none"
                              sx={{ ml: 1, flex: 1 }}
                              type="text"
                              name="itemName"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.itemName}
                              placeholder="Item name or description"
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {" "} */}
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              type="number"
                              name="quantity"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.quantity}
                              placeholder="0"
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {" "} */}
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              type="number"
                              name="unitPrice"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.unitPrice}
                              placeholder="0"
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {" "} */}
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              textalign="right"
                              type="number"
                              name="discount"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.discount}
                              placeholder="0"
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {" "} */}
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              type="number"
                              name="rates"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.rates}
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* {" "} */}
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              type="number"
                              name="amount"
                              onChange={(e) => handleChange(index, e)}
                              value={
                                itemField.quantity * itemField.unitPrice -
                                (itemField.quantity *
                                  itemField.unitPrice *
                                  itemField.discount) /
                                  100
                              }
                              disabled
                            />
                            {/* {" "} */}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              color="error"
                              onClick={() => handleRemoveField(index)}
                            >
                              <DeleteForeverRoundedIcon fontSize="medium" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box>
                  {/* <button onClick={handleAddField}> */}
                  <IconButton
                    color="secondary"
                    aria-label="add item"
                    onClick={handleAddField}
                  >
                    <AddCircleOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>

                  {/* </button> */}
                </Box>
              </Box>

              <Grid item xs={12} md={12} textAlign="right" m="2">
                <Box
                  mr="1.5rem"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="100px"
                  sx={{ flexGrow: 1 }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <Box>
                        <Typography variant="body2">Sub total:</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">VAT 10%:</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">VAT 20%:</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6">Total:</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      {" "}
                      <Typography variant="body2">€ {subTotal}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">€ {vat1}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">€ {vat2}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">€ {total}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    onClick={handleSaveInvoice}
                    variant="outlined"
                    sx={{
                      m: "2rem 1rem",
                    }}
                  >
                    SAVE INVOICE
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                      m: "2rem 0",
                    }}
                  >
                    CANCEL
                  </Button>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  mt="3rem"
                >
                  <Typography variant="caption">
                    Please send payment within 14 days of reiving this invoice.
                    Thank you.
                  </Typography>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditInvoice;

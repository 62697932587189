import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import DateRangePicker from "rsuite/DateRangePicker";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import FlexBetween from "components/FlexBetween";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "redux/store/auth/authSlice";
import { getChaletBookings } from "redux/store/booking/bookingSlice";

const predefinedBottomRanges = [
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "bottom",
    appearance: "default",
  },

  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next 14 days",
    value: [new Date(), addDays(new Date(), 14)],
    appearance: "default",
  },
  {
    label: "Next 30 days",
    value: [new Date(), addDays(new Date(), 30)],
    appearance: "default",
  },
];

const ChaletBookingsList = (chaletId) => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();

  // const isNonMediumScreens = useMediaQuery("(min-width: 1100px)");

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const { chaletBookings, isLoading, isError, message } = useSelector(
    (state) => state.booking
  );

  useEffect(() => {
    if (isLoggedIn === true) {
      // dispatch(getChaletBookings("63e3cf3b919bdf422b213d2e"));
      // chalets is stored as an array in Bookings, so we need to convert it
      dispatch(getChaletBookings(Object.values(chaletId.chaletId)));
      // dispatch(getChaletBookings(Object.values(chaletId)));
      // dispatch(getChaletBookings(chaletId));
    }

    if (isError) {
      console.log(message);
    }
  }, [chaletId, isLoggedIn, isError, message, dispatch]);

  const [allBookings, setAllBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);

  const [value, setValue] = useState([]);

  useEffect(() => {
    setAllBookings(chaletBookings);
    setFilteredBookings(chaletBookings);
  }, [chaletBookings]);

  const handleClick = () => {
    setValue([]);
    setFilteredBookings(chaletBookings);
  };

  const handleChange = () => {
    let filtered = allBookings.filter((booking) => {
      let startDate = new Date(booking["startDate"]);
      let endDate = new Date(booking["endDate"]);
      return startDate >= value[0] && endDate <= value[1];
    });
    console.log("filtered products", filtered);
    setFilteredBookings(filtered);
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "startDate",
      headerName: "Check In",
      // width: 200,
      renderCell: (params) => moment(params.row.startDate).format("DD-MM-YYYY"),
      flex: 0.4,
    },
    {
      field: "endDate",
      headerName: "Check Out",
      renderCell: (params) => moment(params.row.endDate).format("DD-MM-YYYY"),
      flex: 0.4,
    },
    {
      field: "nights",
      headerName: "Nights",
      flex: 0.2,
    },
    {
      field: "adults",
      headerName: "Adults",
      flex: 0.2,
    },
    {
      field: "children",
      headerName: "Children",
      flex: 0.2,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
  ];

  return (
    <>
      <Box m="1rem 0rem">
        <Box
          gridColumn="span 12"
          gridRow="span 1"
          backgroundColor="background.paper"
          // p="1rem"
          borderRadius="0.55rem"
        >
          <FlexBetween>
            <DateRangePicker
              placeholder="Select Date Range"
              showWeekNumbers
              editable={false}
              value={value}
              onChange={setValue}
              onExit={handleChange}
              cleanable={false}
              ranges={predefinedBottomRanges}
            />
            <Button color="secondary" variant="outlined" onClick={handleClick}>
              All Bookings
            </Button>
          </FlexBetween>
          <Box
            height="50vh"
            gridColumn="span 12"
            gridRow="span 1"
            backgroundColor="background.paper"
            mt="1rem"
            borderRadius="0.55rem"
          >
            <DataGrid
              loading={isLoading || !filteredBookings}
              getRowId={(row) => row._id}
              rows={filteredBookings || []}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns _id, the other columns will remain visible
                    _id: false,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChaletBookingsList;

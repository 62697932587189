import axios from "axios";

//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const API_URL = `${SERVER_URL}/api/invoices/`;

// GET INVOICES BY ID
const getInvoiceById = async (id) => {
  const response = await axios.get(`${API_URL}${id}`);
  return response.data;
};

// GET ALL INVOICES
const getInvoices = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// CREATE NEW INVOICE
const createInvoice = async (formData) => {
  const response = await axios.post(API_URL, formData);
  return response.data;
};

// UPDATE INVOICE
const updateInvoice = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

// DELETE INVOICE
const deleteInvoice = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};

const invoiceService = {
  getInvoiceById,
  getInvoices,
  createInvoice,
  updateInvoice,
  deleteInvoice,
};

export default invoiceService;

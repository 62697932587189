import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import invoiceService from "./invoiceService";

const initialState = {
  myinvoice: null,
  invoices: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET INVOICE BY ID
export const getInvoiceById = createAsyncThunk(
  "invoices/getInvoiceById",
  async (id, thunkAPI) => {
    try {
      return await invoiceService.getInvoiceById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET INVOICES
export const getInvoices = createAsyncThunk(
  "invoices/getInvoices",
  async (_, thunkAPI) => {
    try {
      return await invoiceService.getInvoices();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// CREATE NEW INVOICE
export const createInvoice = createAsyncThunk(
  "invoices/create",
  async (formData, thunkAPI) => {
    try {
      return await invoiceService.createInvoice(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// UPDATE INVOICE
export const updateInvoice = createAsyncThunk(
  "invoices/updateInvoice",
  async ({ id, formData }, thunkAPI) => {
    try {
      return await invoiceService.updateInvoice(id, formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE INVOICE
export const deleteInvoice = createAsyncThunk(
  "invoices/delete",
  async (id, thunkAPI) => {
    try {
      return await invoiceService.deleteInvoice(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    CLEAR_INVOICES(state, action) {
      state.myinvoice = undefined;
      state.invoices = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoiceById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.myinvoice = action.payload;
        // state.invoices = action.payload;
      })
      .addCase(getInvoiceById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.invoices = action.payload;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // state.invoices.push(action.payload);
        toast.success("Invoice added successfully");
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Invoice updated successfully");
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
        // toast.error(action.error);
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Invoice deleted successfully");
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { CLEAR_INVOICES } = invoiceSlice.actions;

export const selectIsLoading = (state) => state.user.isLoading;
export const selectInvoice = (state) => state.invoice.myinvoice;

export default invoiceSlice.reducer;

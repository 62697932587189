import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

const editBookingSchema = yup.object().shape({
  //   bookingNumber: yup.string().required("xxx is required"),
  //chalet: yup.string().required("xxx is required"),
  //   startDate: yup.string().required("xxx is required"),
  //   endDate: yup.string().required("xxx is required"),
  //   nights: yup.string().required("xxx is required"),
  //   lastName: yup.string().required("xxx is required"),
  //   adults: yup.string().required("xxx is required"),
  //   children: yup.string().required("xxx is required"),
  //   baby: yup.string().required("xxx is required"),
  //   animals: yup.string().required("xxx is required"),
  //   type: yup.string().required("xxx is required"),
  cleaningHours: yup
    .number()
    .typeError("Please specify a cleaning hours")
    .min(1, "Min value 1.")
    .max(10, "Max value 10.")
    .required("Cleaning hours is required"),
  winterHours: yup
    .number()
    .typeError("Please specify a winter hours")
    .min(0, "Min value 0.")
    .max(10, "Max value 10.")
    .required("Winter hours is required"),
  summerHours: yup
    .number()
    .typeError("Please specify a summer hours")
    .min(0, "Min value 0.")
    .max(10, "Max value 10.")
    .required("Summer hours is required"),
  managementHours: yup
    .number()
    .typeError("Please specify a management hours")
    .min(0, "Min value 0.")
    .max(10, "Max value 10.")
    .required("Management hours is required"),
});

const BookingFormik = ({
  bookingNumber,
  chaletName,
  startDate,
  endDate,
  nights,
  lastName,
  adults,
  children,
  baby,
  animals,
  type,
  cleaningHours,
  winterHours,
  summerHours,
  managementHours,
  bookingStatus,
  partner,
  saveBooking,
  handleClose,
}) => {
  const initialValues = {
    bookingNumber: bookingNumber,
    chaletName: chaletName,
    startDate: startDate,
    endDate: endDate,
    nights: nights,
    lastName: lastName,
    adults: adults,
    children: children,
    baby: baby,
    animals: animals,
    type: type,
    cleaningHours: cleaningHours,
    winterHours: winterHours,
    summerHours: summerHours,
    managementHours: managementHours,
    bookingStatus: bookingStatus,
    partner: partner,
  };

  const [data, setData] = useState(initialValues);

  return (
    <Formik
      //enableReinitialize
      onSubmit={saveBooking}
      initialValues={initialValues}
      validationSchema={editBookingSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                autoFocus
                disabled
                label="Booking"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.bookingNumber}
                name="bookingNumber"
                error={
                  Boolean(touched.bookingNumber) &&
                  Boolean(errors.bookingNumber)
                }
                helperText={touched.bookingNumber && errors.bookingNumber}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                disabled
                label="Chalet"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.chaletName}
                name="chaletName"
                error={
                  Boolean(touched.chaletName) && Boolean(errors.chaletName)
                }
                helperText={touched.chaletName && errors.chaletName}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                disabled
                label="Type"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.type}
                name="partner"
                error={Boolean(touched.type) && Boolean(errors.type)}
                helperText={touched.type && errors.type}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                required
                inputProps={{ inputMode: "numeric", pattern: "[1-9]*" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">hr</InputAdornment>
                  ),
                }}
                // autoFocus
                label="CleaningHours"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.cleaningHours}
                name="cleaningHours"
                error={
                  Boolean(touched.cleaningHours) &&
                  Boolean(errors.cleaningHours)
                }
                helperText={touched.cleaningHours && errors.cleaningHours}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">hr</InputAdornment>
                  ),
                }}
                //  autoFocus
                label="ManagementHours"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.managementHours}
                name="managementHours"
                error={
                  Boolean(touched.managementHours) &&
                  Boolean(errors.managementHours)
                }
                helperText={touched.managementHours && errors.managementHours}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">hr</InputAdornment>
                  ),
                }}
                //autoFocus
                label="Winter hours"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.winterHours}
                name="winterHours"
                error={
                  Boolean(touched.winterHours) && Boolean(errors.winterHours)
                }
                helperText={touched.winterHours && errors.winterHours}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">hr</InputAdornment>
                  ),
                }}
                //   autoFocus
                label="Summer hours"
                onBlur={handleBlur}
                onChange={(e) => {
                  setData({
                    ...data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  });
                  handleChange(e);
                }}
                value={values.summerHours}
                name="summerHours"
                error={
                  Boolean(touched.summerHours) && Boolean(errors.summerHours)
                }
                helperText={touched.summerHours && errors.summerHours}
              />
            </Grid>

            <Divider />
            {/* BUTTONS */}
            <Grid container spacing={0} justifyContent="flex-end">
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  m: "2rem 1rem",
                }}
              >
                SAVE CHALET
              </Button>

              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  m: "2rem 0",
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default BookingFormik;

import React from "react";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import makeStyles from "@mui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//   dialogWrapper: {
//     padding: theme.spacing(2),
//     position: "absolute",
//     top: theme.spacing(5),
//   },
//   dialogTitle: {
//     paddingRight: "0px",
//   },
// }));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, top } = props;
  //   const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      PaperProps={{
        sx: {
          //   bgcolor: "pink",
          //   bgcolor: "#f5f5f5",
          maxWidth: "revert",
          width: "70%",
          //   padding: "spacing(5)",
          position: "absolute",
          //   top: "spacing(5)",
          top: { top } || "10rem",
        },
      }}
      // maxWidth="md"
      //   classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle
        style={{
          paddingRight: "0px",
          backgroundColor: "background.default",
          color: "text.primary",
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Button
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseOutlinedIcon />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

import React from "react";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";

const UploadComponent = (props) => {
  const {
    handleImageChange,
    handleMainImagePreview,
    setFieldValue,
    setData,
    data,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      handleImageChange(acceptedFiles[0]);
      handleMainImagePreview(acceptedFiles[0]);

      setFieldValue("mainImage", acceptedFiles[0]);
      setData({
        ...data,
        // profileImage: acceptedFiles[0].name,
        mainImage: acceptedFiles,
        //mainImage: reader.result,
      });
    },
  });

  return (
    <Box
      {...getRootProps({ className: "dropzone" })}
      border={`0.5px dashed `}
      p="3rem"
      sx={{ "&:hover": { cursor: "pointer" } }}
    >
      <input {...getInputProps()} />
      <Box>Drag & drop your profile picture here</Box>
    </Box>
  );
};

export default UploadComponent;

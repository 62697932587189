import React, { useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChaletFormik from "components/chalet/ChaletFormik";
import { createChalet } from "../../redux/store/chalet/chaletSlice";
import Loader from "components/loader/Loader";

const initialState = {
  chaletName: "",
  chaletNumber: "",
  address: "",
  zipcode: "",
  city: "",
  country: "",
};

const AddChalet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chalet, setChalet] = useState(initialState);
  const [mainImage, setMainImage] = useState("");
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState();

  const { chaletName, chaletNumber, address, zipcode, city, country } = chalet;

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setChalet({ ...chalet, [name]: value });
  // };

  const handleClose = () => {
    // console.log("Cancel clicked");
    setIsLoading(false);
    navigate("/chalets");
  };

  const handleImageChange = (acceptedFiles) => {
    setMainImage(acceptedFiles);
    setMainImagePreview(acceptedFiles[0]);
    //console.log("preview:", acceptedFiles[0]);
  };

  const saveChalet = async (data) => {
    setIsLoading(true);
    // e.preventDefault();
    // console.log("DATA", data);
    // console.log("DATA chalet", data.chaletName);
    const formData = new FormData();
    formData.append("chaletName", data.chaletName);
    formData.append("chaletNumber", data.chaletNumber);
    //formData.append("quantity", Number(quantity));
    formData.append("address", data.address);
    formData.append("zipcode", data.zipcode);
    formData.append("city", data.city);
    formData.append("country", data.country);
    formData.append("mainImage", data.mainImage);

    //console.log(...formData);

    let response = await dispatch(createChalet(formData));
    //console.log("response:", response);

    if (response?.error?.message === "Rejected") {
      setIsLoading(false);
      navigate("/add-chalet");
    } else {
      setIsLoading(false);
      navigate("/chalets");
    }
  };

  return (
    <>
      {/* {isLoading && <Spinner />} */}
      {isLoading && <Loader />}

      {/* <Box m="1rem " backgroundColor="background.paper" sx={{ flexGrow: 1 }}> */}
      <Paper sx={{ p: 2, m: 2.5 }}>
        <Box mb="2.5rem ">
          <Typography variant="h5">Add Chalet</Typography>
          <Divider />
        </Box>

        <ChaletFormik
          // chalet={chalet}
          mainImage={mainImage}
          mainImagePreview={mainImagePreview}
          chaletName={chaletName}
          chaletNumber={chaletNumber}
          address={address}
          zipcode={zipcode}
          city={city}
          country={country}
          //handleInputChange={handleInputChange}
          handleImageChange={handleImageChange}
          saveChalet={saveChalet}
          handleClose={handleClose}
        />
      </Paper>
    </>
  );
};

export default AddChalet;

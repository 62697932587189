import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  Avatar,
  Paper,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import { Loader } from "rsuite";
import { loginUser, logoutUser } from "services/authService";
import {
  SET_LOGIN,
  SET_FIRSTNAME,
  //SET_USER,
  SET_ROLE,
  SET_ADMIN,
  SET_UID,
  SET_PROFILEIMAGEPATH,
  SET_LOGOUT,
} from "redux/store/auth/authSlice";
import { CLEAR_USERS } from "redux/store/user/userSlice";
import { CLEAR_CHALETS } from "redux/store/chalet/chaletSlice";
import { CLEAR_BOOKINGS } from "redux/store/booking/bookingSlice";
import { persistor } from "redux/store";

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  // const clearReduxStore = async () => {
  //   await logoutUser();
  //   // toegevoegd 15-2 om te testen
  //   persistor.purge();
  //   dispatch(SET_LOGOUT());
  //   dispatch(SET_LOGIN(false));
  //   dispatch(SET_ADMIN(false));

  //   dispatch(SET_UID(""));
  //   dispatch(SET_FIRSTNAME(""));
  //   dispatch(SET_ROLE(""));
  //   dispatch(SET_PROFILEIMAGEPATH(""));

  //   dispatch(CLEAR_BOOKINGS());
  //   dispatch(CLEAR_CHALETS());
  //   dispatch(CLEAR_USERS());
  // };

  // clearReduxStore();

  const handleFormSubmit = async (values, onSubmitProps) => {
    setIsLoading(true);

    try {
      const data = await loginUser(formData);
      //console.log("Data:", data);
      if (data) {
        dispatch(SET_LOGIN(true));
        dispatch(SET_UID(data._id));
        dispatch(SET_FIRSTNAME(data.firstName));
        dispatch(SET_ROLE(data.role));
        dispatch(SET_PROFILEIMAGEPATH(data.profileImage?.filePath));

        if (data.role === "Admin") {
          dispatch(SET_ADMIN(true));
          setIsLoading(false);
          navigate("/chalets");
        } else {
          dispatch(SET_ADMIN(false));
          setIsLoading(false);
          navigate("/chalet");
        }
      }
    } catch (error) {
      setIsLoading(false);
    }

    // onSubmitProps.resetForm();
  };

  return (
    <Box>
      {isLoading && <Loader />}
      <Paper
        sx={{
          width: isNonMobile ? "50%" : "93%",
          padding: "2rem",
          margin: "1rem  auto",
          backgroundColor: "background.paper",
        }}
        elevation={3}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb="1rem"
        >
          <Avatar
            sx={{
              bgcolor: "secondary.main",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography textAlign="center" variant="h4" sx={{ mb: "1.5rem" }}>
          Sign In
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  //onChange={handleChange}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.currentTarget.name]: e.currentTarget.value,
                    });
                    handleChange(e);
                  }}
                  value={values.email.toLowerCase()}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [e.currentTarget.name]: e.currentTarget.value,
                    });
                    handleChange(e);
                  }}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              {/* BUTTONS */}
              <Box>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                  }}
                >
                  LOGIN
                </Button>
                {/* <Typography
                  onClick={() => {
                    navigate("/register");
                    resetForm();
                  }}
                  color="text.disabled"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Don't have an account? Sign Up here.
                </Typography> */}
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Login;

import React, { useEffect } from "react";
import useRedirectLoggedOutUser from "customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import { selectRole, selectUid } from "redux/store/auth/authSlice";
import { getChalets } from "redux/store/chalet/chaletSlice";
import Loader from "components/loader/Loader";
import ChaletInfo from "../../components/chalet/ChaletInfo";
import ChaletTabs from "../../components/chalet/ChaletTabs";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const ChaletDetail = () => {
  useRedirectLoggedOutUser("/");
  const dispatch = useDispatch();

  // cid is set by ChaletAction.jsx for a specified chalet to edit
  const location = useLocation();
  // let cid = "0";
  // let filteredChalet = [];
  const [filteredChalet, setFilteredChalet] = useState([]);

  let cid = location?.state?.chaletId || 0;
  const uid = useSelector(selectUid);
  //const isLoggedIn = useSelector(selectIsLoggedIn);
  const role = useSelector(selectRole);

  const { chalets, isLoading, isError, message } = useSelector(
    (state) => state.chalet
  );

  // const { user, isUserLoading, isUserError, userMessage } = useSelector(
  //   (state) => state.user
  // );

  // useEffect(() => {
  //   if (isLoggedIn === true) {
  //     dispatch(getUser(uid));
  //   }

  //   if (isUserError) {
  //     console.log(userMessage);
  //   }
  // }, [isLoggedIn, isUserError, userMessage, uid, dispatch]);

  // useEffect(() => {
  //   if (isLoggedIn === true) {
  //     console.log("call getChalets");
  //     dispatch(getChalets());

  //     setFilteredChalet(
  //       chalets.filter((chalet) => {
  //         return chalet.user._id === uid;
  //       })
  //     );
  //   }

  //   if (isError) {
  //     console.log(message);
  //   }
  // });

  useEffect(() => {
    dispatch(getChalets());

    // console.log("chalets:", chalets);

    if (isError) {
      console.log(message);
    }
  }, [isError, message, dispatch]);

  useEffect(() => {
    // dispatch(getChalets());

    if (role === "Admin" && cid !== 0) {
      setFilteredChalet(
        chalets?.filter((chalet) => {
          return chalet._id === cid;
        })
      );
    } else {
      setFilteredChalet(
        chalets?.filter((chalet) => {
          return chalet.user._id === uid;
        })
      );
    }

    // console.log("uid:", uid);
    // console.log("cid:", cid);
    // console.log("filtered:", filteredChalet);
  }, [chalets]);

  // useEffect(() => {
  //   console.log("in 1e useeffect");
  //   if (isLoggedIn === true) {
  //     console.log("call getChalets");

  //     async function fetchData() {
  //       await dispatch(getChalets());
  //     }
  //     fetchData();
  //   }

  //   if (isError) {
  //     console.log(message);
  //   }
  // }, [isLoggedIn, isError, dispatch, message]);

  // useEffect(() => {
  //   console.log("in 2e useeffect");
  //   if (isLoggedIn === true && role === "Admin" && cid !== 0) {
  //     console.log("call getChaletById");
  //     console.log("in  useEffect cid:", cid);
  //     dispatch(getChaletById(cid));
  //   } else if (isLoggedIn === true) {
  //     console.log("call getChalets");
  //     dispatch(getChalets());
  //   }

  //   if (isError) {
  //     console.log(message);
  //   }
  // }, [isLoggedIn, isError, message, dispatch, cid, uid, role]);

  // if (!isLoading) {
  //   if (role === "Admin" && cid !== 0) {
  //     filteredChalet = chalets.filter((chalet) => {
  //       return chalet._id === cid;
  //     });
  //   } else {
  //     filteredChalet = chalets.filter((chalet) => {
  //       return chalet.user._id === uid;
  //     });
  //   }

  //   console.log("filtered by uid:", filteredChalet);
  // }

  // if (isLoggedIn === true && role === "Admin" && cid !== 0) {
  //   setFilteredChalet(
  //     chalets.filter((chalet) => {
  //       return chalet._id === cid;
  //     })
  //   );
  //   console.log("filtered by cid:", filteredChalet);
  // }

  // useEffect(() => {
  //   console.log("in2e  useeffect");
  //   if (isLoggedIn === true && role === "Admin" && cid !== 0) {
  //     console.log("call getChaletById");
  //     console.log("in 2e useEffect cid:", cid);
  //     dispatch(getChaletById(cid));
  //     // } else if (isLoggedIn === true) {
  //     //   console.log("call getChalets");
  //     //   dispatch(getChalets());
  //   }

  //   if (isError) {
  //     console.log(message);
  //   }
  // }, [isLoggedIn, isError, message, dispatch, cid, role]);

  // console.log("filtered Chalets:", filteredChalet);

  // if (cid === 0) {
  //   console.log("Geen cid: ", cid);
  //   filteredChalet = chalets.filter((chalet) => {
  //     return chalet.user._id === uid;
  //   });
  // } else {
  //   console.log("cid:", cid);
  //   filteredChalet = chalets.filter((chalet) => {
  //     return chalet._id === cid;
  //   });
  // }

  // filteredChalet = chalets;
  // console.log("filtered chalet:", filteredChalet);

  return (
    <>
      {isLoading && <Loader />}

      {chalets && (
        <>
          <ChaletInfo
            //  chalets={filteredChalets}
            cid={cid}
            uid={uid}
            id={filteredChalet[0]?._id}
            chaletName={filteredChalet[0]?.chaletName}
            chaletNumber={filteredChalet[0]?.chaletNumber}
            address={filteredChalet[0]?.address}
            zipcode={filteredChalet[0]?.zipcode}
            city={filteredChalet[0]?.city}
            country={filteredChalet[0]?.country}
            // mainImage={chalet[0].mainImage.filePath}
            mainImage={filteredChalet[0]?.mainImage}
            firstName={filteredChalet[0]?.user.firstName}
            lastName={filteredChalet[0]?.user.lastName}
            email={filteredChalet[0]?.user.email}
          />
          <ChaletTabs
            // chalets={chalets}
            chaletId={filteredChalet[0]?._id}
          />
        </>
      )}
    </>
  );
};

export default ChaletDetail;
